<template>
    <div class="auth-page">
        <a-spin tip="正在授权登陆中...">
            <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
        </a-spin>
    </div>
</template>

<script>
    import {setToken, getToken} from "../common/js/storage";

    export default {
        name: "Auth",
        created() {
            this.login();
        },
        methods: {
            login() {
                let path = this.$route.query.state ? decodeURIComponent(this.$route.query.state) : '/';
                if(getToken()) {
                    this.$router.replace(path);
                } else {
                    let code = this.$route.query.auth_code;
                    this.$axios({
                        url: '/admin/login/auth',
                        method: 'GET',
                        params: {code}
                    }).then(res => {
                        if(res.error == 0) {
                            setToken(res);
                            // location.replace(`${location.protocol}//${location.host}${path}`)
                            this.$router.replace(path);
                        } else {
                            this.$message.warning(res.msg)
                        }
                    });
                }
            },
            permanentAuth() {
                let code = this.$route.query.auth_code;
                this.$axios({
                    url: '/admin/login/test',
                    method: 'GET',
                    params: {code}
                }).then(res => {
                    console.log(res);
                });
            }
        }
    }
</script>

<style >
    .auth-page {
        margin-top: 100px;
        text-align: center;
    }
</style>
