<template>
  <div class="light-box">
    <div class="light-show">
      <div class="light-list">
        <div class="light-item" v-for="(item, i) in list" :key="i">
          <div class="light light-top" :class="[`light-state-${item.state}`]"></div>
          <div class="light light-bottom"></div>
        </div>
      </div>
    </div>
    <div class="light-control">
      <a-list :grid="{ gutter: 16, column: 4 }" :data-source="list">
        <a-list-item slot="renderItem" slot-scope="item, index">
          <a-card :title="`佛像${index + 1}`">
            <a-select v-model="item.state" :options="stateList" style="width: 100%"></a-select>
          </a-card>
        </a-list-item>
      </a-list>
    </div>
  </div>
</template>

<script>
    import {getKeyTitle} from "../common/js/tool";

    const stateList = [
        {key: 1, title: '待供奉'},
        {key: 2, title: '供奉结束'},
        {key: 3, title: '供奉成功'},
        {key: 4, title: '寻灯'},
        {key: 5, title: '远程共修'},
        {key: 6, title: '日常待机状态'},
        {key: 7, title: '初一十五待机'},
        {key: 8, title: '关闭灯光'},
    ]
    const getStateText = state => getKeyTitle(stateList, state);
    export default {
        name: "Light",
        data() {
            return {
                stateList,
                list: [
                    {state: 1},
                    {state: 1},
                    {state: 1},
                    {state: 1},
                    {state: 1},
                    {state: 1},
                    {state: 1},
                    {state: 1},
                ]
            }
        },
        methods: {
            getStateText
        }
    }
</script>

<style scoped lang="less">
  .light-box {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .light-show {
    width: 50%;
    background-color: #000;
  }
  .light-list {
    display: flex;
  }
  .light-item {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }
  .light {
    margin-top: 20px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: rgb(88,88,88);
    &.light-state-1 {
      background-color: rgb(88,88,88);
    }
    &.light-state-2 {
      background-color: rgb(88,88,88);
    }
    &.light-state-3 {
      background-color: rgb(88,88,88);
      animation: flash-mid ease 10s infinite alternate-reverse;
    }
    &.light-state-4 {
      background-color: rgb(122,0,255);
      animation: flash-high ease 2s infinite alternate-reverse;
    }
    &.light-state-5 {
      background-color: rgb(88,88,88);
      animation: flash-mid ease 10s infinite alternate-reverse;
    }
    &.light-state-6 {
      background-color: rgb(88,88,88);
    }
    &.light-state-7 {
      background-color: rgb(88,88,88);
      animation: flash-low ease 10s infinite alternate-reverse;
    }
    &.light-state-8 {
      opacity: 0;
    }
  }
  .light-control {
    flex: 1;
  }
  .light-control {
    padding: 20px;
  }

  @keyframes flash-low {
    0% {
      background-color: rgb(88,88,88);
    }
    50% {
      background-color: rgb(120,120,120);
    }
    100% {
      background-color: rgb(40,40,40);
    }
  }

  @keyframes flash-mid {
    0% {
      background-color: rgb(88,88,88);
    }
    100% {
      background-color: rgb(255,255,255);
    }
  }

  @keyframes flash-high {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
</style>
